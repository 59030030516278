import { FC, Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ContainerPage, TitlePage } from "design-system/components";
import { TermAndConditionStyled } from "./styled";
import { useTitle } from "hooks";
import { colors } from "design-system/theme";
import { ButtonProps, TermAndConditionStyledProps } from "design-system/domain";
import { Grid } from "@mui/material";
import { TermAndConditionAccordion } from "./TermAndConditionAccordion";
import { isMobile } from "react-device-detect";
import { RootState } from "store";

const RegulamentoLink = process.env.REACT_APP_PROGRAM_REGULATION_CONTAINER;
const TermAndConditionPage: FC<TermAndConditionStyledProps & ButtonProps> = ({
  className,
}) => {
  useTitle("Regulamento");
  const { user } = useSelector((state: RootState) => state);
  const navigate = useNavigate();

  return (
    <>
      <ContainerPage>
        <TitlePage title="Regulamento" />
        <TermAndConditionStyled.ContainerSubTitleText>
          <TermAndConditionStyled.SubTitleText>
            Conheça alguns dos principais pontos do regulamento. A versão
            completa pode ser acessada a partir do botão no final da página.
          </TermAndConditionStyled.SubTitleText>
        </TermAndConditionStyled.ContainerSubTitleText>

        <TermAndConditionStyled.Container>
          {!isMobile ? (
            <Grid container spacing={1}>
              <Grid md={3}>
                <TermAndConditionStyled.TermAndConditionBlock
                  backgroundColor={colors.primary.orange02}
                >
                  <TermAndConditionStyled.TermAndConditionBlockTitle
                    color={colors.generics.white}
                  >
                    Programa
                  </TermAndConditionStyled.TermAndConditionBlockTitle>

                  <TermAndConditionStyled.TermAndConditionBlockText
                    color={colors.generics.white}
                  >
                    1.1 O Programa de relacionamento AGREGA (“Programa”) é
                    idealizado, desenvolvido, promovido e administrado pela BASF
                    S.A. (“BASF”), divisão de Proteção de Cultivos Brasil, com o
                    intuito de divulgação de produtos que serão pré-determinados
                    pela BASF e para fomentação dessas vendas. Ele tem como
                    objetivo proporcionar ao agricultor, cliente do Canal de
                    Distribuição e/ou Cooperativa BASF participante, a
                    possibilidade de acumular pontos através da compra de
                    defensivos agrícolas e/ou sementes elegíveis ("Produtos
                    BASF"), podendo trocar tais pontos por prêmios (produtos ou
                    serviços), na forma prevista no presente regulamento.
                  </TermAndConditionStyled.TermAndConditionBlockText>
                </TermAndConditionStyled.TermAndConditionBlock>

                <TermAndConditionStyled.TermAndConditionBlock
                  backgroundColor={colors.neutral.gray03}
                  marginTop="20px"
                >
                  <TermAndConditionStyled.TermAndConditionBlockTitle
                    color={colors.generics.white}
                  >
                    Expiração dos Pontos
                  </TermAndConditionStyled.TermAndConditionBlockTitle>

                  <TermAndConditionStyled.TermAndConditionBlockText
                    color={colors.generics.white}
                  >
                    9.1 Cada ponto disponível para resgate possui o prazo de
                    validade de 24 (vinte e quatro) meses, contados a partir da
                    disponibilização do crédito dos pontos, cuja informação
                    estará disponível nos Sites AGREGA e Conecta, em área de
                    acesso restrito. Após o período aqui descrito, os Pontos
                    serão expirados, sem direito a reembolso.
                  </TermAndConditionStyled.TermAndConditionBlockText>
                </TermAndConditionStyled.TermAndConditionBlock>

                <TermAndConditionStyled.TermAndConditionBlock
                  marginTop="20px"
                  backgroundColor={colors.primary.orange02}
                >
                  <TermAndConditionStyled.TermAndConditionBlockTitle
                    color={colors.generics.white}
                  >
                    Migração de Pontos do Antigo Programa
                  </TermAndConditionStyled.TermAndConditionBlockTitle>

                  <TermAndConditionStyled.TermAndConditionBlockText
                    color={colors.generics.white}
                  >
                    10.1 Os Participantes cadastrados no Programa AGREGA até 10
                    de setembro de 2023 terão seus cadastros e pontos migrados
                    automaticamente para o Site Conecta. Para que seja possível
                    realizar o resgate dos pontos é necessário que o
                    Participante atenda aos critérios de cadastro e
                    elegibilidade dispostos no item 2.7 deste Regulamento, ou
                    seja, os Participantes deverão possuir cadastro válido no
                    Site Conecta.
                    <br />
                    <br />
                    10.1.1. Para que o cadastro do Participante seja considerado
                    válido no Site Conecta, é necessário realizar a redefinição
                    de senha, conforme disposto no item 2.7.2 deste Regulamento.
                    <br />
                    <br />
                    10.2 Os pontos acumulados pelo Participante serão migrados
                    para o Site Conecta, mantendo sua data original de
                    expiração.
                    <br />
                    <br />
                    10.3. O Participante já cadastrado no Programa AGREGA, via
                    CNPJ ou CNPJ Rural, a partir de 18 de setembro de 2023,
                    passará a ter seus pontos computados e seus resgates
                    emitidos em nome do CPF responsável pelo CNPJ em questão. No
                    entanto, para que isto ocorra, o Participante deverá
                    realizar a validação descrita na Cláusula 3, item 3.5, ou
                    seja, realizar o envio da documentação que comprove sua
                    condição de representante legal do CNPJ anteriormente
                    cadastrado.
                  </TermAndConditionStyled.TermAndConditionBlockText>
                </TermAndConditionStyled.TermAndConditionBlock>
              </Grid>
              <Grid md={6}>
                <TermAndConditionStyled.TermAndConditionBlock
                  backgroundColor={colors.primary.orange02}
                >
                  <TermAndConditionStyled.TermAndConditionBlockTitle
                    style={{ marginTop: "-5px" }}
                    color={colors.generics.white}
                  >
                    Regras de Pontuação
                  </TermAndConditionStyled.TermAndConditionBlockTitle>

                  <TermAndConditionStyled.TermAndConditionBlockText
                    color={colors.generics.white}
                  >
                    5.1. A lista com os Produtos elegíveis para conversão de
                    pontos será disponibilizada no Site AGREGA e será atualizada
                    de tempos em tempos. <br />
                    <br />
                    5.2. Os produtos e/ou serviços disponibilizados para
                    resgate, somente poderão ser trocados através da plataforma
                    Conecta Pontos, e os pontos serão contabilizados através da
                    conversão automática do valor dos Produtos em pontos,
                    mediante tratamento, por empresa terceira contratada pela
                    BASF, dos dados de faturamento enviados pelo distribuidor
                    autorizado. <br />
                    <br />
                    5.1.1. A BASF poderá modificar a pontuação dos Produtos ou
                    excluir produtos elegíveis, a qualquer momento, mediante
                    simples comunicação disponibilizada no Site AGREGA. <br />
                    <br />
                    5.1.2 A aquisição de produtos BASF de distribuidores não
                    participantes, conforme definido no item 2.1 e 2.2 deste
                    regulamento, não possibilitará o acúmulo de pontos. <br />
                    <br />
                    5.3. Sistemática de Pontuação <br />
                    <br />
                    5.3.1. Para fins de pontuação, serão consideradas apenas as
                    notas fiscais que contenham Produtos BASF elegíveis ao
                    Programa, e emitidas com algum dos CFOPs (Código Fiscal de
                    Operações e Prestações) listados abaixo: <br />
                    <br />
                    <div style={{ display: "flex" }}>
                      <TermAndConditionStyled.BoxNumbers>
                        5102
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        5104
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        5106
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        5112
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        5114
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        5115
                      </TermAndConditionStyled.BoxNumbers>
                    </div>
                    <div style={{ display: "flex" }}>
                      <TermAndConditionStyled.BoxNumbers>
                        5117
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        5119
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        5120
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        5160
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        5403
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        6102
                      </TermAndConditionStyled.BoxNumbers>
                    </div>
                    <div style={{ display: "flex" }}>
                      <TermAndConditionStyled.BoxNumbers>
                        6104
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        6106
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        6108
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        6110
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        6112
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        6114
                      </TermAndConditionStyled.BoxNumbers>
                    </div>
                    <div style={{ display: "flex" }}>
                      <TermAndConditionStyled.BoxNumbers>
                        6115
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        6117
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        6119
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        6120
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        6403
                      </TermAndConditionStyled.BoxNumbers>
                      <TermAndConditionStyled.BoxNumbers>
                        6160
                      </TermAndConditionStyled.BoxNumbers>
                    </div>
                    <br />
                    <br />
                    5.3.2. Todas as compras de Produtos BASF elegíveis junto aos
                    Canais de Distribuição e/ou Cooperativas BASF realizadas
                    pelos Participantes serão convertidas em pontos: a cada R$
                    1,00 (um real) gasto para Produtos na categoria Lançamento,
                    serão concedidos 2 (pontos), e a cada R$ 1,00 (um real)
                    gasto para os demais produtos elegíveis, será concedido 1
                    (um) ponto, conforme disposto no item 5.1, através da lista
                    de Produtos disponibilizada no Site AGREGA. <br />
                    <br />
                    5.3.3 O preço dos Produtos que será utilizado para cálculo
                    da pontuação será o valor final do produto contido na nota
                    fiscal. <br />
                    <br />
                    5.3.4 A BASF poderá alterar a proporção de compras e pontos
                    a qualquer momento, com comunicação através do Site AGREGA,
                    com antecedência mínima de 60 (sessenta) dias. <br />
                    <br />
                    5.3.5 Qualquer discordância na quantidade de pontos será
                    resolvida através da apresentação, pelo Participante, das
                    notas fiscais de compra dos Produtos, em até 210 (duzentos e
                    dez) dias após a data da emissão da nota fiscal. <br />
                    <br />
                    5.3.6 O Participante começará a pontuar no programa somente
                    após o cadastro no Site Conecta e no Conecta Pontos e aceite
                    aos termos do Programa AGREGA, e não terá direito à
                    pontuação de compras retroativas; a partir do cadastro, o
                    Participante passará a pontuar em todas as compras elegíveis
                    de Produtos participantes realizadas em qualquer Canal de
                    Distribuição e/ou Cooperativas BASF participante do
                    Programa. Para tanto, o Participante deverá conferir a lista
                    de parceiros participantes no Site AGREGA. <br />
                    <br />
                    5.3.7 O extrato de pontos do presente Programa poderá ser
                    acompanhado pelo Participante através do Site AGREGA e pelo
                    Conecta Pontos. <br />
                    <br />
                    5.3.8 Os pontos serão creditados na conta do Participante
                    após 35 (trinta e cinco) dias da data de emissão da nota
                    fiscal das compras efetuadas pelo Participante, no Canal de
                    Página | 5 Internal Distribuição e/ou Cooperativas BASF
                    participantes. A pontuação será enviada para a BASF de forma
                    automática pelo sistema do Canal de Distribuição e/ou
                    Cooperativas participantes, conforme descrito no item 5.2.{" "}
                    <br />
                    <br />
                    5.3.9 Caso o Participante realize a devolução de algum dos
                    Produtos considerados elegíveis, os respectivos pontos serão
                    cancelados e subtraídos da pontuação total do Participante.
                    Caso o Participante já tenha utilizado a pontuação, esse
                    saldo de pontos constará como negativo. Abaixo lista das
                    CFOPs de devolução (inserir): <br />
                    <br />
                    <div style={{ display: "block" }}>
                      <div
                        style={{
                          border: "1px solid white",
                          textAlign: "center",
                        }}
                      >
                        Códigos CFOPs de Devolução
                      </div>
                      <div style={{ display: "flex" }}>
                        <TermAndConditionStyled.BoxNumbers>
                          6115
                        </TermAndConditionStyled.BoxNumbers>
                        <TermAndConditionStyled.BoxNumbers>
                          6117
                        </TermAndConditionStyled.BoxNumbers>
                        <TermAndConditionStyled.BoxNumbers>
                          6119
                        </TermAndConditionStyled.BoxNumbers>
                        <TermAndConditionStyled.BoxNumbers>
                          6120
                        </TermAndConditionStyled.BoxNumbers>
                        <TermAndConditionStyled.BoxNumbers>
                          6403
                        </TermAndConditionStyled.BoxNumbers>
                        <TermAndConditionStyled.BoxNumbers>
                          6160
                        </TermAndConditionStyled.BoxNumbers>
                      </div>
                    </div>
                    <br />
                    <br />
                    5.3.10 Caso um determinado Canal de Distribuição BASF deixe
                    de ser parceiro da BASF no presente Programa, o mesmo deverá
                    comunicar os Participantes de que as compras de Produtos
                    elegíveis através daquele Canal de Distribuição não serão
                    mais pontuadas. A BASF atualizará a lista de distribuidores
                    parceiros no Site AGREGA, contudo não possuirá qualquer
                    gestão sobre a comunicação realizada pelo Canal. <br />
                    <br />
                    5.3.11 No caso de campanhas sazonais promovidas pela BASF,
                    serão aplicadas as regras de acúmulo de pontos, de acordo
                    com seus respectivos regulamentos. <br />
                    <br />
                  </TermAndConditionStyled.TermAndConditionBlockText>
                </TermAndConditionStyled.TermAndConditionBlock>
              </Grid>
              <Grid md={3}>
                <TermAndConditionStyled.TermAndConditionBlock
                  backgroundColor={colors.neutral.gray03}
                >
                  <TermAndConditionStyled.TermAndConditionBlockTitle
                    color={colors.generics.white}
                  >
                    Procedimentos Excepcionais para Situações Emergenciais e
                    Falecimento de Participantes
                  </TermAndConditionStyled.TermAndConditionBlockTitle>
                  <TermAndConditionStyled.TermAndConditionBlockText
                    color={colors.generics.white}
                  >
                    7.1. A transferência de Pontos entre contas será viabilizada
                    mediante o atendimento dos seguintes critérios e condições:
					<br />
					<br />
                    7.1.1. Pessoa física (conta pessoal): Caso o Participante
                    possua uma conta pessoal no Programa, sem agregação a nenhum
                    CNPJ, a transferência de Pontos será possível somente em
                    caso de falecimento do Participante. Nesse cenário, os
                    Pontos serão transferidos exclusivamente ao respectivo
                    inventariante do espólio, mediante apresentação dos
                    seguintes documentos, cumulativamente: (i) Certidão de Óbito
                    e (ii) Termo de Nomeação, Escritura Pública de Inventário e
                    Partilha, ou qualquer outro documento emitido por uma
                    autoridade competente que comprove a qualidade do
                    inventariante.
					<br />
					<br />
					7.1.2. Representante Legal de empresa
                    Participante: Nos casos de falecimento do Representante
                    Legal de uma empresa Participante, demissão, ou quaisquer
                    outras medidas emergenciais que impliquem na transferência
                    de Pontos para outra conta, o responsável pela solicitação
                    deverá entrar em contato com os canais de atendimento do
                    Ecossistema Conecta. Para que a transferência de Pontos seja
                    realizada, é necessário que os seguintes critérios sejam
                    cumpridos, cumulativamente: (i) O solicitante deve fornecer
                    documentação adequada que comprove a situação emergencial,
                    como Certidão de Óbito, comprovante de
                    demissão/desligamento, dentre outros documentos relevantes;
                    e (ii) seja devidamente comprovado que a conta de destino
                    dos Pontos está cadastrada em nome de um Representante Legal
                    constituído, conforme apresentação dos documentos
                    societários e instrumento de mandato específico que comprove
                    a outorga de poderes da empresa Participante ao novo
                    Representante Legal.
					<br />
					<br />
					7.1.2.1. Caso um Representante Legal
                    possua mais de uma empresa agregada em sua conta no
                    Programa, a transferência de Pontos será realizada
                    integralmente para uma única conta, conforme indicado no
                    item 7.1.2,	sem a possibilidade de divisão de Pontos. 
					<br />
					<br />
					7.2. 
					A solicitação de transferência de Pontos estará sujeita à
                    análise prévia da BASF, que se reserva no direito de não
                    atender a uma solicitação caso a documentação fornecida seja
                    insuficiente ou não comprove de forma adequada a qualidade
                    do solicitante. 7.1. A transferência de Pontos entre contas
                    será viabilizada mediante o atendimento dos seguintes
                    critérios e condições: 7.1.1. Pessoa física (conta pessoal):
                    Caso o Participante possua uma conta pessoal no Programa,
                    sem agregação a nenhum CNPJ, a transferência de Pontos será
                    possível somente em caso de falecimento do Participante.
                    Nesse cenário, os Pontos serão transferidos exclusivamente
                    ao respectivo inventariante do espólio, mediante
                    apresentação dos seguintes documentos, cumulativamente: (i)
                    Certidão de Óbito e (ii) Termo de Nomeação, Escritura
                    Pública de Inventário e Partilha, ou qualquer outro
                    documento emitido por uma autoridade competente que comprove
                    a qualidade do inventariante. 7.1.2. Representante Legal de
                    empresa Participante: Nos casos de falecimento do
                    Representante Legal de uma empresa Participante, demissão,
                    ou quaisquer outras medidas emergenciais que impliquem na
                    transferência de Pontos para outra conta, o responsável pela
                    solicitação deverá entrar em contato com os canais de
                    atendimento do Ecossistema Conecta. Para que a transferência
                    de Pontos seja realizada, é necessário que os seguintes
                    critérios sejam cumpridos, cumulativamente: (i) O
                    solicitante deve fornecer documentação adequada que comprove
                    a situação emergencial, como Certidão de Óbito, comprovante
                    de demissão/desligamento, dentre outros documentos
                    relevantes; e (ii) seja devidamente comprovado que a conta
                    de destino dos Pontos está cadastrada em nome de um
                    Representante Legal constituído, conforme apresentação dos
                    documentos societários e instrumento de mandato específico
                    que comprove a outorga de poderes da empresa Participante ao
                    novo Representante Legal. 7.1.2.1. Caso um Representante
                    Legal possua mais de uma empresa agregada em sua conta no
                    Programa, a transferência de Pontos será realizada
                    integralmente para uma única conta, conforme indicado no
                    item 7.1.2, sem a possibilidade de divisão de Pontos. 7.2. A
                    solicitação de transferência de Pontos estará sujeita à
                    análise prévia da BASF, que se reserva no direito de não
                    atender a uma solicitação caso a documentação fornecida seja
                    insuficiente ou não comprove de forma adequada a qualidade
                    do solicitante.
                  </TermAndConditionStyled.TermAndConditionBlockText>
                </TermAndConditionStyled.TermAndConditionBlock>
              </Grid>
            </Grid>
          ) : (
            <TermAndConditionAccordion />
          )}
          <TermAndConditionStyled.TermAndConditionContainerButton>
            <TermAndConditionStyled.TermAndConditionBlockTextCustomized
              color={colors.neutral.gray03}
            >
              <a rel="noreferrer" target="_blank" href={RegulamentoLink}>
                <TermAndConditionStyled.TermAndConditionButtonCustomized
                  className={className}
                  buttonText="Confira o regulamento completo"
                />
              </a>
            </TermAndConditionStyled.TermAndConditionBlockTextCustomized>
          </TermAndConditionStyled.TermAndConditionContainerButton>

          {user.agrega_opt_in_score ? (
            <>
              <TermAndConditionStyled.TermAndConditionBlockTextCustomized
                color={colors.neutral.gray03}
              >
                Você concordou com os termos do regulamento e já faz parte do
                programa AGREGA.
              </TermAndConditionStyled.TermAndConditionBlockTextCustomized>
            </>
          ) : (
            <>
              {user.is_logged ? (
                <TermAndConditionStyled.TermAndConditionContainerButton
                  style={{ borderTop: "none" }}
                >
                  <TermAndConditionStyled.TermAndConditionBlockTextCustomized
                    color={colors.neutral.gray03}
                  >
                    Para participar do programa AGREGA, você deve complementar o
                    seu cadastro.
                    <a onClick={() => (window.location.href = "/cadastro")}>
                      <TermAndConditionStyled.TermAndConditionButtonCustomized2
                        className={className}
                        buttonText="Atualizar Cadastro"
                      />
                    </a>
                  </TermAndConditionStyled.TermAndConditionBlockTextCustomized>
                </TermAndConditionStyled.TermAndConditionContainerButton>
              ) : (
                <Fragment />
              )}
            </>
          )}
        </TermAndConditionStyled.Container>
      </ContainerPage>
    </>
  );
};

export default TermAndConditionPage;
