import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from "design-system/theme";
import { TermAndConditionStyled } from "../styled";

const TermAndConditionAccordionProgram = (): JSX.Element => (
	<>
		<Accordion
			TransitionProps={{ unmountOnExit: true }}
			sx={{
				backgroundColor: colors.neutral.gray03,
				color: colors.generics.white,
				marginBottom: "15px",
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
				aria-controls="panel1a-content"
				id="panel1"
			>
				<Typography
					style={{
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Programa
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>
					1.1 O Programa de relacionamento AGREGA (“Programa”) é idealizado,
					desenvolvido, promovido e administrado pela BASF S.A. (“BASF”),
					divisão de Proteção de Cultivos Brasil. Ele tem como objetivo
					proporcionar ao agricultor, cliente do Canal de Distribuição e/ou
					Cooperativa BASF participante, a possibilidade de acumular pontos
					através da compra de defensivos agrícolas e/ou sementes elegíveis
					("Produtos BASF"), podendo trocar tais pontos por prêmios (produtos ou
					serviços), na forma prevista no presente regulamento.
				</Typography>
			</AccordionDetails>
		</Accordion>
	</>
);

const TermAndConditionAccordionRescue = (): JSX.Element => (
	<>
		<Accordion
			TransitionProps={{ unmountOnExit: true }}
			sx={{
				backgroundColor: colors.primary.orange02,
				color: colors.generics.white,
				marginBottom: "15px",
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
				aria-controls="panel1a-content"
				id="panel2"
			>
				<Typography
					style={{
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
                    Procedimentos Excepcionais para Situações Emergenciais e
                    Falecimento de Participantes
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>
					7.1. A transferência de Pontos entre contas será viabilizada
                    mediante o atendimento dos seguintes critérios e condições:
					<br />
					<br />
                    7.1.1. Pessoa física (conta pessoal): Caso o Participante
                    possua uma conta pessoal no Programa, sem agregação a nenhum
                    CNPJ, a transferência de Pontos será possível somente em
                    caso de falecimento do Participante. Nesse cenário, os
                    Pontos serão transferidos exclusivamente ao respectivo
                    inventariante do espólio, mediante apresentação dos
                    seguintes documentos, cumulativamente: (i) Certidão de Óbito
                    e (ii) Termo de Nomeação, Escritura Pública de Inventário e
                    Partilha, ou qualquer outro documento emitido por uma
                    autoridade competente que comprove a qualidade do
                    inventariante.
					<br />
					<br />
					7.1.2. Representante Legal de empresa
                    Participante: Nos casos de falecimento do Representante
                    Legal de uma empresa Participante, demissão, ou quaisquer
                    outras medidas emergenciais que impliquem na transferência
                    de Pontos para outra conta, o responsável pela solicitação
                    deverá entrar em contato com os canais de atendimento do
                    Ecossistema Conecta. Para que a transferência de Pontos seja
                    realizada, é necessário que os seguintes critérios sejam
                    cumpridos, cumulativamente: (i) O solicitante deve fornecer
                    documentação adequada que comprove a situação emergencial,
                    como Certidão de Óbito, comprovante de
                    demissão/desligamento, dentre outros documentos relevantes;
                    e (ii) seja devidamente comprovado que a conta de destino
                    dos Pontos está cadastrada em nome de um Representante Legal
                    constituído, conforme apresentação dos documentos
                    societários e instrumento de mandato específico que comprove
                    a outorga de poderes da empresa Participante ao novo
                    Representante Legal.
					<br />
					<br />
					7.1.2.1. Caso um Representante Legal
                    possua mais de uma empresa agregada em sua conta no
                    Programa, a transferência de Pontos será realizada
                    integralmente para uma única conta, conforme indicado no
                    item 7.1.2,	sem a possibilidade de divisão de Pontos. 
					<br />
					<br />
					7.2. 
					A solicitação de transferência de Pontos estará sujeita à
                    análise prévia da BASF, que se reserva no direito de não
                    atender a uma solicitação caso a documentação fornecida seja
                    insuficiente ou não comprove de forma adequada a qualidade
                    do solicitante. 7.1. A transferência de Pontos entre contas
                    será viabilizada mediante o atendimento dos seguintes
                    critérios e condições: 7.1.1. Pessoa física (conta pessoal):
                    Caso o Participante possua uma conta pessoal no Programa,
                    sem agregação a nenhum CNPJ, a transferência de Pontos será
                    possível somente em caso de falecimento do Participante.
                    Nesse cenário, os Pontos serão transferidos exclusivamente
                    ao respectivo inventariante do espólio, mediante
                    apresentação dos seguintes documentos, cumulativamente: (i)
                    Certidão de Óbito e (ii) Termo de Nomeação, Escritura
                    Pública de Inventário e Partilha, ou qualquer outro
                    documento emitido por uma autoridade competente que comprove
                    a qualidade do inventariante. 7.1.2. Representante Legal de
                    empresa Participante: Nos casos de falecimento do
                    Representante Legal de uma empresa Participante, demissão,
                    ou quaisquer outras medidas emergenciais que impliquem na
                    transferência de Pontos para outra conta, o responsável pela
                    solicitação deverá entrar em contato com os canais de
                    atendimento do Ecossistema Conecta. Para que a transferência
                    de Pontos seja realizada, é necessário que os seguintes
                    critérios sejam cumpridos, cumulativamente: (i) O
                    solicitante deve fornecer documentação adequada que comprove
                    a situação emergencial, como Certidão de Óbito, comprovante
                    de demissão/desligamento, dentre outros documentos
                    relevantes; e (ii) seja devidamente comprovado que a conta
                    de destino dos Pontos está cadastrada em nome de um
                    Representante Legal constituído, conforme apresentação dos
                    documentos societários e instrumento de mandato específico
                    que comprove a outorga de poderes da empresa Participante ao
                    novo Representante Legal. 7.1.2.1. Caso um Representante
                    Legal possua mais de uma empresa agregada em sua conta no
                    Programa, a transferência de Pontos será realizada
                    integralmente para uma única conta, conforme indicado no
                    item 7.1.2, sem a possibilidade de divisão de Pontos. 7.2. A
                    solicitação de transferência de Pontos estará sujeita à
                    análise prévia da BASF, que se reserva no direito de não
                    atender a uma solicitação caso a documentação fornecida seja
                    insuficiente ou não comprove de forma adequada a qualidade
                    do solicitante.
				</Typography>
			</AccordionDetails>
		</Accordion>
	</>
);

const TermAndConditionAccordionRulesAndScore = (): JSX.Element => (
	<>
		<Accordion
			TransitionProps={{ unmountOnExit: true }}
			sx={{
				backgroundColor: colors.neutral.gray03,
				color: colors.generics.white,
				marginBottom: "15px",
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
				aria-controls="panel1a-content"
				id="panel3"
			>
				<Typography
					style={{
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Regras de Pontuação
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>
					5.1. A lista com os Produtos elegíveis para conversão de pontos será
					disponibilizada no Site AGREGA e será atualizada de tempos em tempos.
					<br />
					<br />
					5.2. Os produtos e/ou serviços disponibilizados para resgate, somente
					poderão ser trocados através da plataforma conecta pontos, e os pontos
					serão contabilizados através da conversão automática do valor dos
					Produtos em pontos, mediante tratamento, por empresa terceira
					contratada pela BASF, dos dados de faturamento enviados pelo
					distribuidor autorizado.
					<br />
					<br />
					5.1.1. A BASF poderá modificar a pontuação dos Produtos ou excluir
					produtos elegíveis, a qualquer momento, mediante simples comunicação
					disponibilizada no Site AGREGA.
					<br />
					<br />
					5.1.2 A aquisição de produtos BASF de distribuidores não
					participantes, conforme definido no item 2.1 e 2.2 deste regulamento,
					não possibilitará o acúmulo de pontos.
					<br />
					<br />
					<b>5.2. Sistemática de Pontuação</b>
					<br />
					<br />
					5.2.1. Para fins de pontuação, serão consideradas apenas as notas
					fiscais que contenham Produtos BASF elegíveis ao Programa, e emitidas
					com algum dos CFOPs (Código Fiscal de Operações e Prestações) listados
					abaixo:
					<br />
					<br />
					<div style={{ display: "flex" }}>
						<TermAndConditionStyled.BoxNumbers>
							5102
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5104
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5106
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5112
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5114
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5115
						</TermAndConditionStyled.BoxNumbers>
					</div>
					<div style={{ display: "flex" }}>
						<TermAndConditionStyled.BoxNumbers>
							5117
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5119
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5120
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5160
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							5403
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6102
						</TermAndConditionStyled.BoxNumbers>
					</div>
					<div style={{ display: "flex" }}>
						<TermAndConditionStyled.BoxNumbers>
							6104
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6106
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6108
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6110
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6112
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6114
						</TermAndConditionStyled.BoxNumbers>
					</div>
					<div style={{ display: "flex" }}>
						<TermAndConditionStyled.BoxNumbers>
							6115
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6117
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6119
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6120
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6403
						</TermAndConditionStyled.BoxNumbers>
						<TermAndConditionStyled.BoxNumbers>
							6160
						</TermAndConditionStyled.BoxNumbers>
					</div>
					<br />
					<br />
					5.2.2. Todas as compras de Produtos BASF elegíveis junto aos Canais de
					Distribuição e/ou Cooperativas BASF realizadas pelos Participantes
					serão convertidas em pontos: a cada R$ 1,00 (um real) gasto para
					Produtos na categoria Lançamento, serão concedidos 2 (pontos), e a
					cada R$ 1,00 (um real) gasto para os demais produtos elegíveis, será
					concedido 1 (um) ponto, conforme disposto no item 5.1, através da
					lista de Produtos disponibilizada no Site AGREGA.
					<br />
					<br />
					5.2.3 O preço dos Produtos que será utilizado para cálculo da
					pontuação será o valor final do produto contido na nota fiscal.
					<br />
					<br />
					5.2.4 A BASF poderá alterar a proporção de compras e pontos a qualquer
					momento, com comunicação através do Site AGREGA, com antecedência
					mínima de 60 (sessenta) dias.
					<br />
					<br />
					5.2.5 Qualquer discordância na quantidade de pontos será resolvida
					através da apresentação, pelo Participante, das notas fiscais de
					compra dos Produtos, em até 210 (duzentos e dez) dias após a data da
					emissão da nota fiscal.
					<br />
					<br />
					5.2.6 O Participante começará a pontuar no programa somente após o
					cadastro no Site Conecta e no conecta pontos e aceite aos termos do
					Programa AGREGA, e não terá direito à pontuação de compras
					retroativas; a partir do cadastro, o Participante passará a pontuar em
					todas as compras elegíveis de Produtos participantes realizadas em
					qualquer Canal de Distribuição e/ou Cooperativas BASF participante do
					Programa. Para tanto, o Participante deverá conferir a lista de
					parceiros participantes no Site AGREGA.
					<br />
					<br />
					5.2.7 O extrato de pontos do presente Programa poderá ser acompanhado
					pelo Participante através do Site AGREGA e pelo conecta pontos.
					<br />
					<br />
					5.2.8 Os pontos serão creditados na conta do Participante após 35
					(trinta e cinco) dias da data de emissão da nota fiscal das compras
					efetuadas pelo Participante, no Canal de Distribuição e/ou
					Cooperativas BASF participantes. A pontuação será enviada para a BASF
					de forma automática pelo sistema do Canal de Distribuição e/ou
					Cooperativas participantes, conforme descrito no item 5.2.
					<br />
					<br />
					5.2.9 Caso o Participante realize a devolução de algum dos Produtos
					considerados elegíveis, os respectivos pontos serão cancelados e
					subtraídos da pontuação total do Participante. Caso o Participante já
					tenha utilizado a pontuação, esse saldo de pontos constará como
					negativo. Abaixo lista das CFOPs de devolução:
					<br />
					<br />
					<div style={{ display: "block" }}>
						<div style={{ border: "1px solid white", textAlign: "center" }}>
							Códigos CFOPs de Devolução
						</div>
						<div style={{ display: "flex" }}>
							<TermAndConditionStyled.BoxNumbers>
								6115
							</TermAndConditionStyled.BoxNumbers>
							<TermAndConditionStyled.BoxNumbers>
								6117
							</TermAndConditionStyled.BoxNumbers>
							<TermAndConditionStyled.BoxNumbers>
								6119
							</TermAndConditionStyled.BoxNumbers>
							<TermAndConditionStyled.BoxNumbers>
								6120
							</TermAndConditionStyled.BoxNumbers>
							<TermAndConditionStyled.BoxNumbers>
								6403
							</TermAndConditionStyled.BoxNumbers>
							<TermAndConditionStyled.BoxNumbers>
								6160
							</TermAndConditionStyled.BoxNumbers>
						</div>
					</div>
					<br />
					<br />
					5.2.10 Caso um determinado Canal de Distribuição BASF deixe de ser
					parceiro da BASF no presente Programa, o mesmo deverá comunicar os
					Participantes de que as compras de Produtos elegíveis através daquele
					Canal de Distribuição não serão mais pontuadas. A BASF atualizará a
					lista de distribuidores parceiros no Site AGREGA, contudo não possuirá
					qualquer gestão sobre a comunicação realizada pelo Canal.
					<br />
					<br />
					5.2.11 No caso de campanhas sazonais promovidas pela BASF, serão
					aplicadas as regras de acúmulo de pontos, de acordo com seus
					respectivos regulamentos.
					<br />
					<br />
					<b>Expiração dos Pontos:</b>
					<br />
					<br />
					9.1 Cada ponto disponível para resgate possui o prazo de validade de
					24 (vinte e quatro) meses, contados a partir da disponibilização do
					crédito dos pontos, cuja informação estará disponível nos Sites AGREGA
					e Conecta, em área de acesso restrito.
				</Typography>
			</AccordionDetails>
		</Accordion>
	</>
);

const TermAndConditionAccordionExpirationScore = (): JSX.Element => (
	<>
		<Accordion
			TransitionProps={{ unmountOnExit: true }}
			sx={{
				backgroundColor: colors.primary.orange02,
				color: colors.generics.white,
				marginBottom: "15px",
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
				aria-controls="panel1a-content"
				id="panel4"
			>
				<Typography
					style={{
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Expiração dos Pontos
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>
					9.1 Cada ponto disponível para resgate possui o prazo de validade de
					24 (vinte e quatro) meses, contados a partir da disponibilização do
					crédito dos pontos, cuja informação estará disponível nos Sites AGREGA
					e Conecta, em área de acesso restrito.
				</Typography>
			</AccordionDetails>
		</Accordion>
	</>
);

const TermAndConditionAccordionMigrationScore = (): JSX.Element => (
	<>
		<Accordion
			TransitionProps={{ unmountOnExit: true }}
			sx={{
				backgroundColor: colors.neutral.gray03,
				color: colors.generics.white,
				marginBottom: "15px",
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
				aria-controls="panel1a-content"
				id="panel5"
			>
				<Typography
					style={{
						fontSize: "20px",
						fontWeight: "600",
					}}
				>
					Migração de Pontos do Antigo Programa
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
					malesuada lacus ex, sit amet blandit leo lobortis eget.
				</Typography>
			</AccordionDetails>
		</Accordion>
	</>
);

const TermAndConditionAccordion = () => {
	return (
		<>
			{TermAndConditionAccordionProgram()}
			{TermAndConditionAccordionRescue()}
			{TermAndConditionAccordionRulesAndScore()}
			{TermAndConditionAccordionExpirationScore()}
			{TermAndConditionAccordionMigrationScore()}
		</>
	);
};

export default TermAndConditionAccordion;
